.orderBillUploadDiv {
    position: relative;
    overflow: hidden;
    background-color: #46bcff;
    width: 121px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color: #ffffff;
    border-radius: 29px;
    margin-top: 6px;
    cursor: pointer !important;
}