.search-filter-dropdown
{
    display:flex;
    align-items: center;
    
}
.ant-table-column-sorter{
    color: #ffff !important;
    /* right: -29px;
    position: absolute; */
}
.ant-table-column-sorter span{
    font-size: 9px;
}

