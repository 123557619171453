.table-quill div {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 32px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.table-quill span:hover {
    color: #46bcff;
}
.table-quill .see_more {
  font-size: 10px;
  color: grey;
  float: right;
}
.ant-table-body::-webkit-scrollbar {
  width: 0.3em !important;
  height: 0.3em !important;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #46BCFF !important;
  outline: 1px solid slategrey !important;
}

.ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(220 241 252 / 30%) !important;
}
.menu-main-class .ant-dropdown-menu-item:hover  .ant-dropdown-menu-title-content div span {
  color: #46bcff !important;
}