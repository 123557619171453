.timePicker input {
    text-align: center;

}

.timePicker {
    width: 40%;
}

.multi-select-dropdown .ant-select-selector {
    border-radius: 28px !important;
}

.multi-select-dropdown .ant-select-selection-overflow {
    background-color: #fff !important;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-radius: 9999px;
    padding-left: 0.1rem;
    padding-right: 0.1rem;

}

.ant-select-tree-checkbox.ant-select-tree-checkbox-disabled {
    display: none;
}

.StatesOfSpainDropDown .ant-select-selector {
    border: none !important;
}

.StatesOfSpainDropDown .ant-select-selector:hover {
    border: none !important;
    /* outline-color: red;
    border-color: blue; */
}

.StatesOfSpainDropDown .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    /* border-color: #40a9ff; */
    /* border-right-width: 1px !important; */
    outline: 0;
    box-shadow: none !important;
    /* box-shadow: 0 0 0 2px rgb(255 24 24 / 95%); */
}

.StatesOfSpainDropDown .ant-select-multiple .ant-select-selection-item {
    background: #ffff;
    border: 1px solid #ffff;
    padding-inline-start: 4px;
}

.StatesOfSpainDropDown .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
    justify-content: center;
}

.StatesOfSpainDropDown .ant-select-selection-overflow {
    height: 3rem;
    overflow: hidden;
}

.StatesOfSpainDropDown .ant-select-tree-list-scrollbar {}

.ant-select-tree-list-scrollbar-thumb {
    background: #46bcff !important;
}

/* bg-white select-input py-2.5 px-3  justify-center rounded-full   */

.ant-picker-panel .ant-picker-footer {
    display: none !important;
}


.add-service-provider-section .ant-select-arrow {
    right: 24px !important;

}