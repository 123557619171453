.supplementary_Charges_option .step-container {
    min-height: auto !important;
    max-height: 100% !important;
    overflow-y: clip !important;
}
.supplementary_Charges_option .ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
    padding-bottom: 0;
}

.chat-white-color-background {
    background-color: #ffff;
}