.userDetailCardStates {
  /* background-color: red; */
  height: 65px ;
  overflow: auto;
  padding-right: 20px;
}
.userDetailCardStates .ant-list-item {
    padding: 4px 0;
    font-size: 12px;
}
.userDetailCardStates::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background: #ededed;
    
}
.userDetailCardStates::-webkit-scrollbar-thumb {
    background-color: #46bcff;
    border-radius: 10px;

}
.userDetailCardServices {
    height: 65px ;
    overflow: auto;
    padding-right: 20px;
}

.userDetailCardServices .ant-list-item {
    padding: 4px 0;
    font-size: 12px;
}
.userDetailCardServices::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background: #ededed;
    
}
.userDetailCardServices::-webkit-scrollbar-thumb {
    background-color: #46bcff;
    border-radius: 10px;

}