/* .uploadFileInputDiv{
    position: absolute;
    font-size: 50px;
    opacity: 0;
    top: 0;
} */

.uploadFileInputDiv{
    position: relative;
    overflow: hidden;
    background-color: #46bcff;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color: #ffffff;
    margin-top: 6px;
    cursor:pointer;
}
 input[type='file'] {
    opacity:0    
  }