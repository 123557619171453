
.select-branch-modal-branches-container::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background: #ededed;
}
.select-branch-modal-branches-container::-webkit-scrollbar-thumb {
    background-color: #46bcff;
    border-radius: 10px;

}
