.Functionality-And-Features-modal-scroll::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  background: #ededed;
}

.Functionality-And-Features-modal-scroll::-webkit-scrollbar-thumb {
  background-color: #46bcff;
  border-radius: 10px;
}
.Functionality-And-Features-modal-scroll::before {
    right: -3px;
    background-color: #040404 !important;
  }