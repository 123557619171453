/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap"); */
@font-face {
  font-family: "ProximaNova-Regular";
  src: local("ProximaNova-Regular"),
    url("../assets/fonts/ProximaNova-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: local("ProximaNova-Bold"),
    url("../assets/fonts/ProximaNova-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-SemiBold"),
    url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Proxima Nova-Bold";
  src: local("Proxima Nova-Bold"),
    url("../assets/fonts/Proxima Nova-Bold.ttf") format("truetype");
}

body {
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px;
}

#welcome-heading {
  font-family: "Proxima Nova-Bold" !important;
  color: #000000 !important;
  font-family: 28px !important;
}

.ant-modal-body {
  background-color: #fafafa !important;
  border-radius: 15px !important;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.input-style {
  box-shadow: none !important;
}

.text-area-outline {}

.select-input {}

.select-input:focus {
  outline: none !important;
}

select option {
  background-color: #fff !important;
  color: #000 !important;
}

.text-area-outline:focus {
  outline: none !important;
}

.data-prize-style {
  font-family: "Proxima Nova-Bold" !important;
  font-weight: bolder !important;
}

.proximaHeadings {
  font-family: "ProximaNova-Bold";
  font-size: 16px;
}

.ant-table-wrapper {
  clear: both !important;
  max-width: 97% !important;
}

span,
label,
p {
  font-family: "ProximaNova-Regular" !important;
  /* font-size: 16px !important; */
}

.proximaRegular {
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px !important;
}

.form-border {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  text-align: center;
}

.cards-border {
  border-width: 1px;
  background-color: white;
}

/* .email {
    border-radius: 20px !important;
    height: 50px;
} */
.border-radius20 {
  border: none;
  border-radius: 20px !important;
  padding: 10px !important;
}

.border-radius10 {
  border: none;
  border-radius: 40px !important;
  padding: 10 !important;
}

/* .btn {
    border-radius: 15px !important;
    padding: 12px 0 33px 0 !important;
} */
.padding-sign-button {
  padding-top: 10px !important;
  padding-bottom: 28px !important;
}

.bg-img {
  background-image: url("../assets/Img.png") !important;
  background-position: right !important;
  background-repeat: no-repeat !important;
  background-size: 50% !important;
}

.full-page-bg-color {
  background-color: #fff;
}

/* .card {
    border-radius: 10px !important;
    min-width: 270px;
    height: 60%; */
/* } */
.icon-button {
  display: flex !important;
  align-items: center !important;
}

.bt1 {
  padding: 20px 80px !important;
  background-color: #d50000 !important;
}

.top-absolute-40 {
  top: 35%;
}

/* 
.styling-table .ant-table-thead .ant-table-cell{
    background-color: #5ab3f0;
}
.styling-table .ant-table-thead{
    border-radius: 2rem;
}
.styling-table{
    border-radius: 22px !important;
} */
.heading-color {
  color: #646464;
}

.bg-primary {
  background-color: #46bcff !important;
  /* background-image: linear-gradient(to right, #00d0ff, #00e0ff) !important; */
  border: 0 !important;
}

.check-circle {
  width: 25px;
  height: 25px;
}

/* .ant-steps-item-content > :nth-child(n) {
  width: 100% !important;
  padding: 0 !important;
} */
.drag-and-drop-container {
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgb(177, 177, 177);
  margin-top: 15px;
  border-radius: 10px;
}

.text-color-primary {
  color: #46bcff !important;
}

.bg-danger {
  background-color: red !important;
  border: 1px solid red !important;
}

.text-white {
  color: #ffffff !important;
}

.bg-white {
  background-color: #fff !important;
  border: 0 !important;
}

thead tr th {
  background-color: #46bcff !important;
}

thead {
  border-radius: 22px !important;
}

.ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 16px !important;
}

.ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 16px !important;
}

.ant-table-container table>thead>tr:first-child th {
  padding: 10px;
  border: hidden !important;
  color: #fff !important;
  text-align: center;
}

.ant-table-bordered {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.ant-table-container {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.background-color-green {
  background-color: #52ad0a !important;
}

.background-color-gray {
  background-color: #a7aaab !important;
}

.font-color-blue {
  color: #46bcff;
}

.font-color-red {
  color: red;
}

button:focus {
  outline: none !important;
}

input:focus-visible {
  outline: none;
}

.whiteTxt {
  color: #fff !important;
}

/* modal styling */
.ant-modal-close-x {
  font-size: 12px !important;
}

.ant-modal-content {
  border-radius: 20px !important;
}

.datePickerStyling {
  border: none !important;
  background-color: #f8f8f8 !important;
  color: darkgray !important;
}

.menu-main-class {
  border-radius: 20px !important;
}

.fileBtn {
  position: absolute;
}

.height45 {
  height: 45px !important;
}

.borderBottomDivider {
  width: 98% !important;
  margin-inline: auto !important;
}

.hh3 {
  font-size: 17px !important;
  font-family: "ProximaNova-Bold", sans-serif !important;
  font-weight: 600;
  text-align: left;
  color: #000000;
  opacity: 0.61;
}

.hh4 {
  font-size: 14px !important;
  font-family: "ProximaNova-Bold", sans-serif !important;
  font-weight: "400";
  text-align: left;
  color: #000000;
  /* opacity: 0.61; */
}

.mh64 {
  min-height: 16rem;
}

.Otp {
  background-color: "red";
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

.modalH {
  margin-top: 4px;
  margin-bottom: 2px;
  font-size: 16px;
  font-family: "ProximaNova-Bold", sans-serif !important;
}

.p0 {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.font-color-Dblue {
  color: #46bcff;
}

.hh01 {
  font-size: 17 !important;
  font-weight: 600;
  text-align: left;
  color: #000000;
  opacity: 0.61;
}

.bulletColor li::before {
  content: "\2022";
  color: #46bcff;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.richTextContainer {
  height: fit-content;
}

.richTextBox {
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
  min-height: 170px;
}

.richTextBox1 {
  background-color: white;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
  min-height: 100px !important;
  overflow-y: auto !important;
  width: 370px !important;
}

.richTextBox .ql-toolbar.ql-snow,
.richTextBox1 .ql-toolbar.ql-snow {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.richTextBox .ql-container.ql-snow,
.richTextBox1 .ql-container.ql-snow {
  border: 1px solid #ccc;
  min-height: 130px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.table-quill .ql-toolbar.ql-snow {
  display: none !important;
  border: none !important;
}

.table-quill .ql-container.ql-snow {
  border: none !important;
}

.lab1 {
  font-family: "ProximaNova-Bold" !important;
  font-size: 16px !important;
  font-weight: 800;
  text-align: left;
  color: #000000;
  opacity: 0.61;
}

.lab2 {
  font-family: "ProximaNova-Bold" !important;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #000000;
  opacity: 0.61;
}

.graylab {
  font-family: "ProximaNova-Bold" !important;
  font-size: 16px !important;
  font-weight: 500;
  text-align: center;
  color: grey;
  opacity: 0.61;
}

.wid-70 {
  width: 70px;
}

.redLab {
  font-family: "ProximaNova-Bold" !important;
  font-size: 18px !important;
  font-weight: 400;
  text-align: left;
  color: red;
  opacity: 0.61;
}

.BLab {
  font-family: "ProximaNova-Bold" !important;
  font-size: 18px !important;
  font-weight: 400;
  text-align: left;
  color: black;
  opacity: 0.61;
}

h1,
h2,
h3,
h4 {
  letter-spacing: normal !important;
  font-family: "Proxima Nova-Bold" !important;
  margin-bottom: 0px !important;
}

.message-time {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 100;
}

.steps_Dot_Style .ant-steps-icon-dot {
  margin-top: 21px !important;
}

.steps_Dot_Style .ant-steps-item-tail {
  margin-top: 21px !important;
}

.steps_Dot_Style .ant-steps-item-title {
  width: 100% !important;
}

.stepper-add-button {
  margin-top: 15px !important;
}

.step-container {
  min-height: 100px !important;
  max-height: 300px !important;
  overflow-y: auto !important;
}

/* .container {
  min-width: 100% !important;
  padding: 0 100px 0 100px !important;
} */
.detail-box {
  margin-top: -240px;
}

.provider-detaol-box {
  margin-top: -60px !important;
}

.bg-disabled {
  background: lightgray !important;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-content {
  display: flex !important;
  min-height: 48px !important;
  overflow: hidden !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.align-nav-items {
  justify-content: flex-start;
}

.container {
  min-width: 100% !important;
}

.sub-h3 {}

.sub1-h3 {}

.activeMenuStyle {
  background-color: rgba(128, 128, 128, 0.1) !important;
}

.date-year {}

.date-year:focus {
  outline: none !important;
}

.worker-message {
  width: 20% !important;
  min-width: 60% !important;
  box-shadow: 0px 3px 1px 2px rgba(128, 128, 128, 0.1) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#selected-provider> :nth-child(1) {
  font-family: "ProximaNova-Bold" !important;
}

#personal-detial> :nth-child(1) {
  font-family: "ProximaNova-Bold" !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.font-normals {
  font-weight: 400 !important;
}

.welcome-text {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.country-menu {
  min-width: 91px !important;
  left: 16px !important;
  top: 0px !important;
  height: 200px !important;
  overflow-y: auto !important;
}

@media screen and (max-width: 415px) {
  .align-nav-items {
    justify-content: flex-end !important;
  }

  .nav-container {
    padding: 0 5px 0 5px !important;
  }

  .nav-header {
    justify-content: flex-start !important;
  }

  .bg-img {
    background-position: center !important;
    background-size: 100% 100vh !important;
  }
}

@media screen and (max-width: 376px) {
  .align-nav-items {
    justify-content: flex-end !important;
  }

  .nav-container {
    padding: 0 5px 0 5px !important;
  }

  .nav-header {
    justify-content: flex-start !important;
  }

  .bg-img {
    background-position: center !important;
    background-size: 100% 100vh !important;
  }

  .sub-h3>h3 {
    font-size: 14px !important;
  }

  .sub1-h3>h3 {
    font-size: 10px !important;
  }

  .worker-message {
    min-width: 100% !important;
  }
}

@media screen and (max-width: 360px) {
  .align-nav-items {
    justify-content: flex-end !important;
  }

  .container {
    max-width: 3000px !important;
  }

  .nav-container {
    padding: 0 5px 0 5px !important;
  }

  .nav-header {
    justify-content: flex-start !important;
  }

  .bg-img {
    background-position: center !important;
    background-size: 100% 100vh !important;
  }

  .sub-h3>h3 {
    font-size: 14px !important;
  }

  .sub1-h3>h3 {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 320px) {
  .align-nav-items {
    justify-content: flex-end !important;
  }

  .nav-container {
    padding: 0 5px 0 5px !important;
  }

  .nav-header {
    justify-content: start !important;
  }

  .bg-img {
    background-position: center !important;
    background-size: 100% 100vh !important;
  }

  .sub-h3>h3 {
    font-size: 14px !important;
  }

  .sub1-h3>h3 {
    font-size: 10px !important;
  }
}

.ant-tag-close-icon {
  margin-left: 3px;
  color: white !important;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
  /* background-color: yellow; */
  position: absolute;
  top: 2;
  top: 6px;
  right: 10px;
}

.uploadDiv {
  position: relative;
  overflow: hidden;
  background-color: #46bcff;
  width: 160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #ffffff;
  margin-top: 6px;
  cursor: pointer !important;
}

.uploadInput {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}


.render-html-class .ql-container.ql-snow.ql-disabled {
  border: 0;
}

.render-html-class .quill .ql-toolbar {
  display: none;
}

.chat-ui-container .render-html-class .ql-editor {
  padding: 0 !important;
  overflow-y: visible;
}