/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap"); */
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h3 {
  font-size: 22px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  text-align: left;
  color: #636363;
}


body::-webkit-scrollbar {
  width: 0.3em !important;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(220, 241, 252, 0.3) !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #46BCFF !important;
  outline: 1px solid slategrey !important;
}


.scroll-table::-webkit-scrollbar {
  width: 0.3em !important;
}

.scroll-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(220, 241, 252, 0.3) !important;
}

.scroll-table::-webkit-scrollbar-thumb {
  background-color: #46BCFF !important;
  outline: 1px solid slategrey !important;
}

@media screen and (max-width:376px) {
  h3 {
    font-size: 18px !important;
  }
}

@media screen and (max-width:360px) {
  h3 {
    font-size: 17px !important;
  }
}

@media screen and (max-width:321px) {
  h3 {
    font-size: 14px !important;
  }
}

.ant-pagination-options {
  margin-left: auto;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #46bcff rgba(220, 241, 252, 0.3);
}

.global-modal-css-scroll-height .scrollable-fixed-height {
  max-height: 70vh !important;
  overflow-y: auto !important;
}


.global-modal-css-scroll-height .scrollable-fixed-height::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  background: #ededed;

}

.global-modal-css-scroll-height .scrollable-fixed-height::-webkit-scrollbar-thumb {
  background-color: #46bcff;
  border-radius: 10px;

}

