
.select-service-modal-branches-container::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background: #ededed;
}
.select-service-modal-branches-container::-webkit-scrollbar-thumb {
    background-color: #46bcff;
    border-radius: 10px;

}

.select-serviceProvider-modal-container::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background: #ededed;
}
.select-serviceProvider-modal-container::-webkit-scrollbar-thumb {
    background-color: #46bcff;
    border-radius: 10px;

}
.pointer-event-none{
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.2;
}
.pointer-event-auto{
    pointer-events: auto;
}