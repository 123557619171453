.dispute-user-provider-admin-chat-container .border-primary-message-sender {
  border-color: #46bcff;
  border-width: 1px;
  background-color: #ffff;
}
.border-primary-message-sender ol {
  list-style: auto;
}
.border-primary-message-sender ul {
  list-style: disc;
}
.dispute-user-provider-admin-chat-container .dispute-chat-ui-container::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  background: #ededed;
}

.dispute-user-provider-admin-chat-container .dispute-chat-ui-container::-webkit-scrollbar-thumb {
  background-color: #46bcff;
  border-radius: 10px;
}

.dispute-user-provider-admin-chat-container .place-self-end::before {
  right: -3px;
  background-color: #040404 !important;
}

.dispute-user-provider-admin-chat-container .ant-spin-nested-loading>div>.ant-spin {
  max-height: 100% !important;
}

.dispute-user-provider-admin-chat-container .document_icon {
  width: 20px;
  height: 20px;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 200px;
  padding: 48px;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.sideBarDocumentsList {
  height: 65px;
  overflow: auto;
  padding-right: 20px;
}

.sideBarDocumentsList .ant-list-item {
  padding: 4px 0;
  font-size: 12px;
}

.sideBarDocumentsList::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  background: #ededed;
}

.sideBarDocumentsList::-webkit-scrollbar-thumb {
  background-color: #46bcff;
  border-radius: 10px;
}

.side-bar-scroll::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  background: #ededed;
}

.side-bar-scroll::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  background: #ededed;
}

.side-bar-scroll::-webkit-scrollbar-thumb {
  background-color: #46bcff;
  border-radius: 10px;
}

.ant-tabs-styling .ant-tabs-nav-wrap {
  display: flex;
  justify-content: flex-end;
}

.chatDocsDiv .ant-collapse-content .ant-collapse-content-box {
  max-height: 30rem !important;
  overflow-y: scroll !important;
  padding-bottom: 120px
}

.chatDocsDiv .ant-collapse-content .ant-collapse-content-box::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  background: #ededed;
}

.chatDocsDiv .ant-collapse-content .ant-collapse-content-box::-webkit-scrollbar-thumb {
  background-color: #46bcff;
  border-radius: 10px;
}

.notification-ui-container .ant-spin-container {
  height: 35rem;
  overflow-y: scroll;
}

.notification-ui-container .ant-spin-container::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  background: #ededed;
}

.notification-ui-container .ant-spin-container::-webkit-scrollbar-thumb {
  background-color: #46bcff;
  border-radius: 10px;
}

.view-datails-blue-color {
  color: #46bcff !important;
}